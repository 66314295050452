// React
import React, { useState } from "react";

// Material UI
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import StopIcon from "@mui/icons-material/Dangerous";
import ContinueIcon from "@mui/icons-material/GppGood";

// Images
import BatteriesPlusLogo from "./images/batteries-plus-logo-3.png";
import FreedomSolarLogo from "./images/freedom-solar-logo-3.png";

// CSS
import "./App.css";

function BatteriesPlugLogoImage() {
    const dimensions = {
        width: 883,
        height: 218,
    };

    const scale = 0.3;

    dimensions.width *= scale;
    dimensions.height *= scale;

    return (
        <img
            src={BatteriesPlusLogo}
            alt="Batteries Plus Logo"
            width={dimensions.width}
            height={dimensions.height}
        />
    );
}

function FreedomSolarLogoImage() {
    const dimensions = {
        width: 2210,
        height: 346,
    };

    const scale = 0.12;

    dimensions.width *= scale;
    dimensions.height *= scale;

    return (
        <img
            src={FreedomSolarLogo}
            alt="Freedom Solar Logo"
            width={dimensions.width}
            height={dimensions.height}
        />
    );
}

function App() {
    const [storeNumber, setStoreNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [storeNumberError, setStoreNumberError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);

    const changeStoreNumber = (e) => {
        setStoreNumber(e.target.value);
        setStoreNumberError(false);
    };

    const changeFirstName = (e) => {
        setFirstName(e.target.value);
        setFirstNameError(false);
    };

    const changeLastName = (e) => {
        setLastName(e.target.value);
        setLastNameError(false);
    };

    const validationConfigs = [
        {
            field: "storeNumber",
            value: storeNumber,
            error: storeNumberError,
            setError: setStoreNumberError,
        },
        {
            field: "firstName",
            value: firstName,
            error: firstNameError,
            setError: setFirstNameError,
        },
        {
            field: "lastName",
            value: lastName,
            error: lastNameError,
            setError: setLastNameError,
        },
    ];

    const validateForm = () => {
        let valid = true;
        validationConfigs.forEach((config) => {
            if (config.value === "") {
                config.setError(true);
                valid = false;
            } else {
                config.setError(false);
            }
        });
        return valid;
    };

    const bookingGridUser = `${storeNumber}+${firstName}_${lastName}@batteriesplus.com`;
    const bookingGridParams = {
        user: bookingGridUser,
        region: "a0V5w00000JTv4AEAT",
        lang: "English",
        jobType: "In Home",
        handoff: "Email",
        leadsrc: "Batteries Plus",
        yearHomeBuilt: "2023",
        meterOnMainHome: "yes",
    };

    const bookingGridUrlParams = new URLSearchParams(bookingGridParams);
    const bookingGridUrl = `https://d5w000003ujmueac.my.salesforce-sites.com/bookinggrid?${bookingGridUrlParams.toString()}`;

    const launchBookingGrid = () => {
        const validForm = validateForm();
        if (validForm) {
            window.open(bookingGridUrl);
            return true;
        } else {
            return false;
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "50px",
                    marginBottom: "50px",
                }}
            >
                <BatteriesPlugLogoImage />
                <Box
                    sx={{
                        textAlign: "center",
                        marginTop: "0px",
                        marginBottom: "15px",
                        fontSize: "30px",
                    }}
                >
                    +
                </Box>
                <FreedomSolarLogoImage />
            </Box>
            <Box style={{ display: "flex" }} color="orange">
                <WarningIcon />
                <Typography variant="body1">
                    FIRST, ask customer: "Are you the homeowner?"
                </Typography>
            </Box>
            <Box style={{ display: "flex" }} color="green">
                <ContinueIcon />
                <Typography variant="body1">Yes: Continue.</Typography>
            </Box>
            <Box style={{ display: "flex" }} color={"red"}>
                <StopIcon />
                <Typography variant="body1">
                    No: Stop (Not Qualified).
                </Typography>
            </Box>
            <TextField
                label="Store Number"
                fullWidth
                margin="normal"
                onChange={changeStoreNumber}
                error={storeNumberError}
                type="number"
            />
            <TextField
                label="Associate First Name"
                fullWidth
                margin="normal"
                onChange={changeFirstName}
                error={firstNameError}
            />
            <TextField
                label="Associate Last Name"
                fullWidth
                margin="normal"
                onChange={changeLastName}
                error={lastNameError}
            />
            <Button
                variant="contained"
                fullWidth
                sx={{ marginTop: 3 }}
                onClick={launchBookingGrid}
            >
                <Typography fontWeight={800}>
                    Book an Appointment with Freedom Solar
                </Typography>
            </Button>
        </Container>
    );
}

export default App;
